@import "~antd/dist/antd.less";

.state-roles-n8-submenu > .ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.app-layout {
  min-height: 100vh;
}

.ant-layout {
  display: flex;
  flex-direction: column;
}

.app-footer {
  background: #fff;
  margin-top: auto;
  max-height: 220px;
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 770px;
    height: 91px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 100px;
    width: 1024px;
  }
  .pro-tip-logo {
    margin-bottom: 15px;
    margin-left: auto;
  }
  .pro-tip-multi {
    margin-left: 70px;
  }
}

.app-footer > .ant-row > .ant-col {
  max-width: 330px;
  max-height: 220px;
}

.app-footer > .ant-row > .ant-col > img {
  height: 50px;
  width: 197px;
  justify-self: center;
  align-self: start;
}

.app-footer > .ant-row > .ant-col > .clientImage {
  height: 50px;
  width: auto;
  justify-self: center;
  align-self: start;
}

@font-face {
  font-family: "Titan One";
  src: local("Titan One"),
    url("./assets/fonts/TitanOne-Regular.ttf") format("truetype");
}

@primary-color: #f26522;